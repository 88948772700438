input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload-2 {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img { max-height: 100px; }

input:checked ~ span {
    font-weight: bold;
    color: #374151;
}

.placeholder {
    background-image: url('/src/assets/images/sedanPlaceholder.png');
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#scroller-wrapper {
    width: 100%;
}

#scroller {
    height: 100%;
    overflow-x: scroll;
    white-space: nowrap;
}

.elem {
    height: 100%;
    display: inline-block;
}

#scroller::-webkit-scrollbar {
    display: none;
}

.mid { padding-top: 50%; }

.bgImage {
    background-image: url('/src/assets/images/bg1.png');
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center;
    background-size: cover;
}

.queColor { color: #6E6E70; }

.ansColor { color: #777777; }

.faqBox {
    background: #FFFFFF;
    box-shadow: 0px 20px 24px rgba(153, 155, 168, 0.15);
    border-radius: 6px;
}

.top-text { color: #0D0A19; }

.text-login { color: #9A9EA7; }

.unSelectedBorder { border-color: #EAEFFE; }

.questions-bg {
    position: absolute;
    z-index: -1;
}

.ql-bubble .ql-editor { padding: 5px 0px; font-size: large; }

.bg-button { background-color: #5E83F4; }

.ql-editor a {
    color: #06c;
    text-decoration: underline;
}

.top { margin-left: 50%; }

.top-bg {
    /* padding-top: 1%; */
    position: absolute;
    z-index: -1;
}

.bg-about { background: #F7F9FE; }

.about-color { color: #0D0A19; }

.about-text { color: #777777; }
